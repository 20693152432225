export const Dashboard = {
    SessionCount: 'Sessions',
    EventCount: 'Events',
    CustomerCount: 'Customers',
    Ordercount: 'Orders',
    SessionList: 'Upcoming Sessions',
    EventList: 'Upcoming Events',
    ClassName: 'Class Name',
    DateLable: 'Date',
    Time: 'Time',
    EventName: 'Event Name',
    Schedule: 'Schedule',
    Amount: 'Amount',
    HostedBy: 'Hosted By',
    NoSessionRecordFound: 'No any upcoming sessions record found!',
    NoEventRecordFound: 'No any upcoming events record found!',
    Overview: 'Overview'
}

export const AppointmentLabelConstants = {
    Appointment: 'Appointment',
    AppointmentType: 'Appointment Type',
    Accepted: 'Accepted',
    Approved: 'Approved',
    AddressL1: 'Address Line 1',
    Address: 'Address',
    Accept: 'Accept',
    Amount: 'Amount',
    Approve: 'Approve',
    AppointmentConfirmation: 'Appointment Confirmation',
    AreYouSureReject: 'Are you sure you want to Reject',
    AppointmentApproved: 'Appointment Approved Succesfully',
    Actions: 'Actions',
    AppointmentDate: 'Appointment Date',
    CustomerName: 'Customer Name',
    City: 'City',
    Country: 'Country',
    Cancel: 'Cancel',
    Date: 'Date',
    Email: 'Email',
    Filter: 'Filter',
    Link: 'Link',
    Message: 'Message',
    NoRecordFound: 'No any appointment record found!',
    OrderDate: 'Order Date',
    Pending: 'Pending',
    Rejected: 'Rejected',
    Status: 'Status',
    Sure: 'Sure',
    State: 'Address Line 2',
    Reject: 'Reject',
    ReasonReject: 'Reason For Rejection',
    Phone: 'Phone',
    Time: 'Time',
    ZipCode: 'Zip Code',
}

export const CouponLabelConstants = {
    Actions: 'Actions',
    Add: 'Add',
    AddNew: 'Add New',
    AutoGenerate: 'Auto Generate',
    Cancel: 'Cancel',
    CouponCode: 'Coupon Code',
    CouponDescription: 'Coupon Description',
    CouponType: 'Coupon Type',
    Special: 'Special',
    General: 'General',
    Discount: 'Discount',
    DiscountType: 'Discount Type',
    EndDate: 'End Date',
    ExpireDate: 'Expire Date',
    Filter: 'Filter',
    Flat: 'Flat',
    NoRecordFound: 'No any coupon code record found!',
    Percentage: 'Percentage',
    Reward: 'Reward',
    SrNo: '#',
    StartDate: 'Start Date',
    TotalParticipants: 'Total Participants',
    Update: 'Update',
    SendReward: 'Send Reward',
    NoOfCustomerUsed: "No. Of Customer Used",
    Status: 'Status',
    Active: 'Active',
    InActive: 'InActive',
    FilterCouponType: 'Select Coupon Type',
    FilterStatus: 'Select Status',
    clearall: 'Clear all',
    applyall: 'Apply',
    InValidData: "Select proper data for apply filter",
    SelectDate: "Select Date or Date Range",
};

export const CustomerLabelConstants = {
    Actions: 'Actions',
    AddressL1: 'Address Line 1',
    Address: 'Address',
    AddNew: 'Add New',
    Add: 'Add',
    Cancel: 'Cancel',
    City: 'Town/City',
    Country: 'Country',
    Email: 'Email',
    Filter: 'Filter',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Name: 'Name',
    PhoneNumber: 'Phone Number',
    PostalCode: 'Postal Code',
    EventAttended: 'Event Attended',
    SessionAttended: 'Session Attended',
    State: 'Address Line 2',
    Update: 'Update',
    CustomerList: 'Customer List',
    NoRecordFound: 'No any customer record found!',
    CustomerPreview: 'Customer Preview',
    AddNewCustomer: 'Add New Customer',
    EditCustomer: 'Edit Customer',
    Status: 'Status',
    Active: 'Active',
    InActive: 'InActive',
    HealthConcern: 'Health Concern Note',
    Export: "Export"
};

export const commonLabelConstants = {
    CouponDetails: 'Coupon Details',
    ChangeStatus: 'Change Status',
    AreYouSure: 'Are you sure you want to',
    Yes: 'Yes',
    No: 'No Thanks',
    Logout: 'Logout',
    Cancel: 'Cancel',
    Delete: 'Delete',
    UnsavedChanges: 'Are you sure you want to leave this page? You have unsaved changes.',
    CopyRight: 'Copyright',
    Rights: 'All rights reserved.',
    MyAccount: 'My Account',
    OK: 'Ok',
    youAreUnable: "You are unable to",
    delete: "delete",
    CouponConfirmationTitle: "this coupon code because this coupon was already used by the customer.",
    youCan: "You can",
    thisCouponCode: "this Coupon Code.",
    goBack: "Go Back"
}

export const ClassLabelConstants = {
    ClassList: "Class List",
    Actions: 'Actions',
    NoRecordFound: 'No any class record found!',
    ClassName: "Class Name",
    SessionCount: "Session Count",
    ViewSession: "View Sessions",
    AddClass: "Add Class",
    Filter: "Filter",
    Date: "Date",
    Type: "Class Type",
    Export: "Export",
    FreezeSession: 'Freeze Session',
    UnFreezeSession: 'Unfreeze Session'
}

export const AddEditClassLabelConstants = {
    AddNewClass: "Add Class",
    UpdateClass: "Update Class",
    ClassName: "Class Name",
    SessionDate: "Session Date",
    SessionTime: "Session Time",
    AddSessionSlot: "Add Session Slot",
    Slot: "Slot",
    SlotTime: "Slot Time",
    SessionSlot: "Session Slot",
    Days: "Days",
    Date: "Date",
    Add: "Add",
    SessionStartDate: "Session Start Date",
    SessionEndDate: "Session End Date",
    AddLink: "Link",
    HostedBy: "Hosted By",
    ParticipantLimit: "Participant Limit",
    ClassDescription: "Class Description",
    SingleSession: "Single Session",
    MultipleSession: "Multiple Session",
    AddClass: "Save",
    SaveClass: "Update",
    ConflictOccurs: 'Conflict occurs in slots',
    StartEndTimeValidation: "Class start date must be less than end date",
    SelectAllFields: "Enter/Select required fields to add session slot.",
    Cancel: "Cancel"
}

export const SessionFilterConstant = {
    ClearAll: "Clear All",
    Apply: "Apply",
    SelectDate: "Select Date or Date Range",
    InvalidSelection: "Select proper date range to apply filter"
}

export const CloneSessionConstant = {
    CloneSessionSlot: "Clone Session Slot",
    CurrentDateSlots: "Current Date Slots",
    Date: "Date:",
    Slot: "Slot:",
    Time: "Time:",
    SelectDateForClone: "Select Date for Clone",
    To: "To",
    Clone: "Clone",
    Cancel: "Cancel",
    SuccessfullyCloned: "Successfully Cloned",
    SessionsNotCloned: "Current sessions are not cloned for these dates",
    Done: "Done",
    SelectDateAndSlot: "Select atleast one date and slot to clone",
    SelectDate: "Select atleast one date to clone",
    SelectSlot: "Select atleast one slot to be cloned",
    Weekly: "Weekly"
}

export const SessionLabelConstants = {
    ClassList: "Class List",
    Actions: 'Actions',
    NoRecentRecordFound: 'No any recent session record found!',
    NoPastRecordFound: 'No any past session record found!',
    ConflictNote: 'Note : A few slot(s) were detected with a start time less than/equal to the end time or with time conflicts with another session, or user is freezed for that time period, check and then save/update it again.',
    ConflictEventNote: 'Note : A few slot(s) were detected with event slot date and time conflicts, are you sure you want to save this class?',
    ClassName: "Class Name",
    Date: "Date",
    Time: "Time",
    HostedBy: "Hosted By",
    ParticipantLimit: "Participant Limit",
    MaxParticipant: "Maximum Participant",
    AttendedParticipant: "Attended Participant",
    NoOfParticipant: "No. Of Participant",
    SessionDetails: "View Details",
    SessionList: "Sessions List",
    Attendance: "Attendance",
    Filter: "Filter",
    Save: "Save",
    Cancel: "Cancel",
    RecentSession: "Most Recent Sessions, Followed By Future Sessions",
    PastSession: "Past Sessions"
}

export const SessionAttendanceLabelConstants = {
    Actions: 'Actions',
    NoRecordFound: 'No any session attendance record found!',
    CustomerName: "Customer Name",
    Date: "Date",
    Time: "Time",
    Absent: "Absent",
    Pending: "Pending",
    Present: "Present",
    Attended: "Attended",
    Attendance: "Attendance",
    AttendanceOf: "Attendance of",
    Filter: "Filter",
    Save: "Save",
    Cancel: "Cancel",
    TotalParticipant: "Total Participant",
    MarkAllPresent: "Mark all as present",
    MarkAllAbsent: "Mark all as absent",
    MarkAllPending: "Mark all as pending",
    ThreeDotIcon: "more_vert",
    SessionBookedDateTime: "Session Booked Date & Time"
}

export const SessionCustomerLabelConstants = {
    Actions: 'Actions',
    NoRecordFound: 'No any session customer record found!',
    CustomerName: "Name",
    EmailId: "Email Id",
    PhoneNumber: "Phone Number",
    Address: "Address",
    Filter: "Filter",
}

export const EventCustomerLabelConstants = {
    NoRecordFound: 'No any event customer record found!',
    CustomerName: "Name",
    EmailId: "Email Id",
    PhoneNumber: "Phone Number",
    TotalParticipant: "Event Participant(s)",
    Address: "Address",
    Qty: "Quantity",
    Price: "Price",
    Filter: "Filter",
    qty: "Qty:"
}

export const EventLabelConstants = {
    Actions: 'Actions',
    AddEvent: 'Save',
    Add: 'Add Event',
    AddImage: 'Add Image',
    AddLocation: 'Add Location',
    AddNew: 'Add New',
    Address: 'Address',
    AddressL1: 'Address Line 1',
    Cancel: 'Cancel',
    City: 'Town/City',
    Contact: 'Contact',
    Country: 'Country',
    DateRange: 'Select Date',
    TimeRange: 'Select Time',
    Event: 'Event',
    Events: 'Events',
    EventAddress: 'Event Address',
    EventAddressL1: 'Event Address Line 1',
    EventDates: 'Event Dates',
    EventDetails: 'Event Details',
    EventFees: 'Event Fees',
    EventName: 'Event Name',
    EventQandA: 'Event Question And Answers',
    EventTime: 'Event Time',
    Fees: 'Fees',
    Filter: 'Filter',
    HostedBy: 'Hosted By',
    NoImageSelected: 'No images selected',
    NoOfParticipants: 'Max. No. Of Participants',
    NoQuestions: 'No any question(s) selected yet',
    NoQuestionRecordFound: 'No any question record found!',
    InvalidContactNumber: 'Contact number is invalid',
    NoRecordFound: 'No any event record found!',
    NoOptions: 'No options available.',
    Participants: 'Participants',
    QuestionName: 'Question Name',
    QuestionType: 'Question Type',
    RemoveImage: 'Remove Image',
    SelectAll: 'Select All',
    SelectQuestion: 'Select The Questions You Like.',
    SrNo: '#',
    State: 'Address Line 2',
    Description: 'Event Description',
    UploadGalleryImages: 'Upload Gallery Images',
    UploadImage: 'Upload Event Image',
    Update: 'Update',
    ZipCode: 'Zip Code',
    MaxParticipantLimit: 'Max. Participants',
    Mandatory: "Mandatory",
    Date: 'Date',
    Time: 'Time',
    Save: "Save",
    ConflictNote: 'Note : A few slot(s) were detected with time conflicts, check and save/update again',
    ConflictEventNote: 'Note : A few slot(s) were detected with event slot date and time conflicts, are you sure you want to save this event?',
    Export: "Export"
};

export const EventFilterConstant = {
    ClearAll: "Clear All",
    Apply: "Apply",
    SelectDate: "Select Date or Date Range",
    SelectHost: "Select Hosted By",
    InvalidDateSelection: "Select proper date range to apply filter"
}

export const EventAttendanceLabelConstants = {
    Actions: 'Actions',
    CustomerName: "Customer Name",
    OrderDate: "Order Date",
    Date: "Date",
    Time: "Time",
    TotalQuantity: "Total Quantity",
    AttendanceOf: "Attendance of",
    Absent: "Absent",
    Attended: "Attended",
    Present: "Present",
    Filter: "Filter",
    TotalParticipant: "Total Participant",
    NoRecordFound: "No any event attendance record found!",
    Save: "Save",
    Cancel: "Cancel"
}

export const EventQuestionConstants = {
    Option: 'Option',
    QuestionType: 'Question Type',
    QuestionText: 'Question Text',
    add: "Add",
    update: "Update",
    cancel: "Cancel"
}


export const ForgotPassLabelConstants = {
    BackToLogin: 'Back To Login',
    Email: 'Enter Email',
    ForgotPassword: 'Forgot Password',
    GoBack: 'GO TO BACK',
    ResetPassword: 'Reset Password',
    SendLink: 'Send Reset Link On Email',
};

export const EventQuestionLabels = {
    FormBuilder: 'Form Builder',
    Filter: 'Filter',
    AddNew: 'Add New',
    SrNo: '#',
    Question: 'Question',
    QuestionType: 'Question Type',
    Actions: 'Actions',
    NoRecordFound: 'No any question record found!'
}

export const LoginLabelConstants = {
    Email: 'Email',
    ForgotPass: 'Forgot Password?',
    Login: 'Login',
    Password: 'Password',
};

export const MyAccountLabelConstants = {
    Address: 'Address',
    AddressL1: 'Address Line 1',
    ChangePassword: 'Change Password',
    City: 'Town/City',
    ConfirmPassword: 'Confirm Password',
    Country: 'Country',
    Email: 'Email',
    FirstName: 'First Name',
    LastName: 'Last Name',
    MyAccount: 'My Account',
    NewPassword: 'New Password',
    OldPassword: 'Old Password',
    PhoneNumber: 'Phone Number',
    PostalCode: 'Postal Code',
    State: 'Address Line 2',
    UpdateProfile: ' Update Profile',
    UserProfile: 'User Profile'
};

export const PaymentDetailLabelConstants = {
    Actions: 'Actions',
    Add: 'Add',
    AddNew: 'Add New',
    Amount: 'Amount',
    DiscountType: 'Discount Type',
    ExpiryDays: 'Expiry Days',
    Filter: 'Filter',
    Limited: 'Limited',
    NoOfPass: 'No Of Sessions',
    NoRecordFound: 'No any pass record found!',
    PassName: 'Pass Name',
    Payment: 'Passes',
    PassType: 'Pass Type',
    SrNo: '#',
    Text: 'Pass Description',
    Update: 'Update',
    Unlimited: 'Unlimited',
    Cancel: 'Cancel',
    action: 'Payment',
    Transactions: 'Transactions',
    PassPreview: 'Pass Preview ',
    EditPass: 'Edit Pass',
    AddPass: 'Add Pass',
    viewDetails: 'View Details'
};

export const ResetPassLabelConstants = {
    ConfirmPassword: 'Confirm Password',
    GoBack: 'GO TO BACK',
    NewPassword: 'New Password',
    Password: 'Password',
    ResetPassword: 'Reset Password',
    expiredLink: "This link is expired. please resend link to reset password"
};

export const RewardCouponConstants = {
    Coupon: 'Coupon',
    Customer: 'Customer',
    Discount: 'Discount',
    ExpireDate: 'Expire Date',
    SendRewardDate: 'Reward Sent Date',
    Filter: 'Filter',
    NoRecordFound: 'No any rewarded customer record found!',
    RewardedCoupon: 'Rewarded Coupons',
    SrNo: '#',
    StartDate: 'Start Date',
    Email: 'Email',
    title: 'Max. Session/Event Attended Participates',
    sendReward: 'Send Reward',
    Cancel: 'Cancel',
    participate: "Participate",
    sessionsAttended: "Sessions/Events Attended",
    NoParticipantsFound: "No any participants found!",
    participatesList: "Participates List",
    details: "Details",
    viewOrderDetails: "View Order Details",
    Active: 'Active',
    InActive: 'InActive',
    Expired: 'Expired',
    Deleted: 'Deleted',
    Status: 'Status',
    sendingRewardMessage: 'Please select at least one customer for sending reward',
    FilterStatus: 'Select Status',
    clearall: 'Clear all',
    applyall: 'Apply',
    InValidData: "Select proper data for apply filter",
    SelectDate: "Select Date or Date Range"
};

export const OrdersConstants = {
    OrderDateTime: 'Order Date - Time',
    Customer: 'Customer Name',
    Amount: 'Amount',
    DiscountCoupon: 'Discount Coupon',
    Actions: 'Action',
    Off: 'off',
    TO: 'TO',
    Orders: 'Orders',
    Email: 'Email',
    Phone: 'Phone',
    Address: 'Address',
    OrderDate: 'Order Date',
    OrderTime: 'Order Time',
    SESSIONS_EVENTS: 'SESSIONS & EVENTS',
    Rate: 'Rate',
    SubTotal: 'Sub Total',
    YogaInstructor: 'Hosted By',
    NoRecordFound: 'No any order record found!',
    NoQueAnsRecordFound: 'No any ordered event question-answer record found!',
    EventQuestion: 'Event Question',
    CouponCode: 'Coupon Code',
    TAX: 'TAX',
    Total: 'Total',
    Filter: "Filter",
    OrderDetails: 'Order Details',
    ViewQueAns: 'View Que - Ans',
    participatesList: "Participates List",
    AddOrder: "Add Order",
    WhoHasAdded: "Who Has Added",
    PaymentType: "Payment Method",
    IsFitToPracticeYoga: "Are You Fit to Practice / Not Pregnant ?"
};

export const OrderFilterConstant = {
    ClearAll: "Clear All",
    Apply: "Apply",
    SelectDate: "Select Order Date or Order Date Range",
    InvalidSelection: "Select proper date range to apply filter"
}

export const RoleLabelConstants = {
    Actions: 'Actions',
    Add: 'Add',
    AddNew: 'Add New',
    Cancel: 'Cancel',
    RoleManagement: 'Role Management',
    RoleName: 'Name of Role',
    NoRecordFound: 'No role record found!',
    Srno: '#',
    TypeRoleName: 'Role Name',
    Update: 'Update'

};

export const SettingLabelConstants = {
    Update: 'Update',
    Cancel: 'Cancel',
    AddContent: 'Add Content',
    AddButton: 'Add 15 Minutes Video Call',
    NoRecordFoundAboutSection: ' No record found for About Content.',
    AddContentImage: 'Add Content Images',
    AddTestimonial: 'Add Testimonial',
    NoRecordFoundTestimonial: "No record found for Testimonials.",

    // About Image Dialog Constants
    AboutImages: 'About Images',
    UploadAboutImage: 'Upload About Images',
    DragDrop: 'Drag and drop your files hear',
    FileSupported: 'File supported: .JPG, .PNG',
    Or: '- or -',
    Browsefiles: 'Browse files',
    NoImageSelected: 'No Images Selected',
    Upload: 'Upload',
    ImagePreview: 'Uploaded Image Preview:',
    NoImageUploaded: 'No any image upload yet!',
    Add: 'Add',

    // About Section Form Dialog Constants
    ImageAlignment: 'Image Alignment',
    AddActionButton: 'Add Action Button',
    AddCall: ' Add 15 Minutes Video Call',
    ContentTitle: 'Content Title',
    UploadImage: 'Upload Image',
    Content: 'Content',

    // Testimonial Form Dialog Constants
    TestimonialContent: 'Testimonial Content',
    PersonName: 'Person Name',

    //ContactUs Constants 
    ContactUs: 'Contact Us',
    Email: 'Admin Email Address',
    Tax: 'Tax',

    // Social Media Constanes
    SocialMedia: "Social Media",
    LinkRequired: "link is required",
    InvalidUrl: "link is invalid",
    checkedRequired: "Selected social links are required to be filled",
    emailRequired: "Email is required.",
    invalid: "Contact Us Email is invalid.",
    taxInvalid: 'The provided tax value is out of the acceptable range. Please ensure that the value falls between 1 and 100',
    error: 'An error occurred while fetching About Content.',
    errortestinomial: 'An error occurred while fetching testimonials.'
}

export const UserLabelConstants = {
    Actions: 'Actions',
    Add: 'Add',
    AddNew: 'Add New',
    Address: 'Address',
    AddressL1: 'Address Line 1',
    Cancel: 'Cancel',
    City: 'Town/City',
    Country: 'Country',
    Email: 'Email',
    FirstName: 'First Name',
    Filter: 'Filter',
    Image: 'Image',
    LastName: 'Last Name',
    NameOfRole: 'Name Of Role',
    NoRecordFound: 'No any user record found!',
    PhoneNumber: 'Phone Number',
    PostalCode: 'Postal Code',
    Role: 'Role',
    State: 'Address Line 2',
    Update: 'Update',
    UserManagement: 'User Management',
    UserPreview: 'User Preview',
    AddUser: 'Add User',
    EditUser: 'Edit User',
    Status: 'Status',
    Active: 'Active',
    InActive: 'InActive',
    FilterUserRole: 'Select Role',
    FilterUserStatus: 'Select Status',
    clearall: 'Clear all',
    applyall: 'Apply',
    InValidData: "Select proper data for apply filter"
};

export const TransactionDetails = {
    title: 'Transaction Details',
    totalAmount: 'Total Amount Received',
    passPrice: 'Pass Price',
    buyCount: 'Total Buy Count',
    customerName: 'Customer Name',
    transactionTime: 'Transaction Time',
    transactionID: 'Transaction ID',
    paymentMode: 'Payment Mode',
    amount: 'Amount',
    norecordfound: 'No any transaction record found!',
    passes: 'Passes'
}


export const ReportLabelConstants = {
    reports: "Customer Request",
    reportPending: "Report Pending",
    name: "Name",
    eventSessionName: " Event/Session Name",
    reportType: "Report Type",
    transactionId: "Transaction Id : ",
    refundedAmount: "Refunded Amount : ",
    dateTime: "Date & Time",
    details: "Details",
    instructorNmae: "Instructor Name",
    actions: "Actions",
    approve: "Approve",
    reject: "Reject",
    approved: "Approved",
    rejected: "Rejected",
    refund: "Refund",
    approveWithRefund: "Approve with Refund",
    cancel: "Cancel",
    noReportRecordFound: "No any reports record found!",
    doYouWantToApprove: "Do you want to Approve?",
    doYouWantToReject: "Do you want to Reject?",
    approveReport: "Approve Report",
    reportStatus: "Report Status",
    approveStatus: "Approve Status",
    rejectReport: "Reject Report",
    rejectStatus: "Reject Status",
    reporter: "Reporter",
    yogaInstructor: "Hosted By",
    requestedQty: "Requested Qty",
    customerMessage: "Customer Message",
    WriteMessageForApproveRequest: "Write a message for Approve the request",
    WriteMessageForRejectRequest: "Write a message for Reject the request",
    approvalMessage: "Approval message",
    rejectionMessage: "Rejection message",
    approveQty: "Approve Qty",
    AmountToBeRefunded: "Amount to be refunded",
    reportDateTime: "Report Date Time",
    Filter: 'Filter',
    FilterAction: 'Actions',
    FilterReportType: 'Report Type',
    clearall: 'Clear all',
    applyall: 'Apply',
    InValidData: "Select proper data for apply filter",
    SelectDate: "Select Date or Date Range",
    maxAmountReRefundable: "Maximum Refundable Amount : "
}

export const AddNewSessionLabelConstant = {
    addNewSession: "Add New Session",
    extraSession: "Extra Session",
    anySession: "Any Session",
    reporter: "Reporter",
    replyTo: "Reply to",
    add: "Add",
    cancel: "Cancel"
}

export const CustomerActivityLabelConstant = {
    activities: "Activities",
    total: "Total",
    superariYoga: "Superari Yoga",
    attended: "Attended",
    sessions: "Sessions",
    pending: "Pending",
    passLeft: "Session Left :",
    purchaseOn: "Purchase On :",
    expireOn: "Expire On :",
    addSession: "Add Session",
    description: "Description",
    attendedSessionsList: "Sessions List",
    booked: "Booked",
    absent: "Absent",
    noCustomerSessionRecordFound: "No any customer session record found!",
    attend: "Attend",
    view: "View",
    upcoming: "Upcoming",
    transactionDetails: "TRANSACTION DETAILS",
    transactionTime: "Transaction Time : ",
    transactionId: "Transaction ID : ",
    paymentMode: "Payment Mode :",
    details: "DETAILS",
    eventQuestions: "EVENT QUESTIONS",
    location: "LOCATION",
    noCustomerEventRecordFound: "No any customer event record found!",
    inProcess: "In process",
    approved: "Approved",
    rejected: "Rejected",
    noCustomerReportRecordFound: "No any customer report record found!",
    refunded: "Refunded",
    filter: "Filter",
    noCustomerRefundRecordFound: "No any customer refund record found!",
    IsFitToPracticeYoga: "Are You Fit to Practice / Not Pregnant ?"
}

export const CustomerRefundStatusLabelConstant = {
    refundDetails: "Refund Details",
    reporter: "Reporter",
    customerMessage: "Customer Message",
    replyToReporter: "Reply To Reporter",
    details: "Details",
    transactionId: "Transaction ID : ",
    refundedAmount: "Refunded Amount : "
}

export const CustomerReportStatusLabelConstant = {
    replyTheReport: "Reply to the Report",
    reporter: "Reporter",
    hostedBy: "Hosted By : ",
    customerMessage: "Customer Message",
    replyToReporter: "Reply To Reporter"
}

export const SessionAddSuccessLabelConstant = {
    addSessionSuccess: " Add Session Success",
    sessionAddedSuccesfully: "Session Added Succesfully",
    ok: "Ok"
}

export const UnderMainatanceLabelConstant = {
    title: "Sorry!! We are under maintenance",
    mainParagraph: "Our website is currently undergoing some upgrades, we will be up and running soon.",
    paragraph: "Thank you for your patience."
}

export const RewardCouponSuccessLabelConstant = {
    couponReward: "Coupon Reward",
    para: "Special Rewards Coupon Sent Successfully for",
    text: "Participant.",
    view: "View"
}

export const SidebarLabelConstant = {
    dashboard: "Dashboard",
    userManagement: "User Management",
    sessions: "Sessions",
    events: "Events",
    classes: "Classes",
    customers: "Customers",
    orders: "Orders",
    formBuilder: "Form Builder",
    passes: "Passes",
    couponCode: "Coupon Code",
    rewardsCoupon: "Rewards Coupon",
    reports: "Customer Request",
    settings: "Settings",
    logout: "Logout"
}

export const sessionCancelWarning = {
    sorry: "sorry....",
    warning: "Warning",
    title: "There are booked sessions within this class, please cancel them first and then you can remove this class.",
    areYouSure: "Are you sure you want to delete",
    thisSession: "this Session?",
    addOnSessionToPass: "Add one session to each customer's pass",
    extendExpiryDate: "Extend pass expiry date.",
    days: "Day(s)",
    note: "Note :",
    someClinetHaveunlimitedPass: "Some of the clients have Unlimited passes.",
    yes: "Yes",
    no: "No",
    delete: "Delete"
}

export const UnFreezeSessionDialogConstants = {
    UnfreezeSessionOfInstructor: 'Unfreeze Session Of Instructor(s)',
    SelectInstructor: 'Select Instructor(s)',
    SelectDate: 'Select Date',
    SelectTime: 'Select Time',
    StartDate: 'Start Date',
    StartTime: 'Start Time',
    EndDate: 'End Date',
    EndTime: 'End Time',
    Search: 'Search',
    HostedBy: 'Hosted By',
    ClassName: 'Class Name',
    Date: 'Date',
    Time: 'Time',
    Participants: 'Participants',
    SelectInstructorAbove: 'Select an instructor(s), date and time from the option above.',
    UnfreezeSession: 'Unfreeze Session'
}

export const HostFreezeLabelConstant = {
    confirmFreeze: "Confirm To Freeze",
    areYouSure: "Are you sure you want to",
    freezeTheInstructor: "freeze the instructor(s) ?",
    addOnSessionToPass: " Add one session to each pass.",
    extendExpiryDate: "Extend pass expiry date.",
    days: "Day(s)",
    note: "Note :",
    someClinetHaveunlimitedPass: "Some of the clients have Unlimited passes.",
    yes: "Yes",
    no: "No",
}

export const FreezeSessionLabelConstant = {
    title: "Freeze Session Of Instructor(s)",
    selectYogaInstructor: "Select Instructor(s)",
    selectStartDate: "Start Date",
    selectStartTime: "Start Time",
    selectEndDate: "End Date",
    selectEndTime: "End Time",
    search: "Search",
    hostedBy: "Hosted By",
    className: "Class Name",
    date: "Date",
    time: "Time",
    participants: "Participants",
    note: "Select an instructor(s) and date and time from the option above.",
    freezeSession: "Freeze Session"
}

export const BookedCustomerListLabelConstant = {
    BookedCustomerList: "Booked Session Customer List",
    CustomerName: "Customer Name",
    Email: 'Email',
    PassExpiryDate: "Pass Expiry Date",
    PassStatus: "Pass Status",
    NoRecordFound: "No any customer record found!",
    ClassName: "Class Name :"
}

export const sessionDeleteCustomer = {
    AreYouSure: 'Are you sure you want to',
    addOnSessionToPass: "Add one session to each customer's pass",
    extendExpiryDate: "Extend pass expiry date.",
    days: "Day(s)",
    note: "Note :",
    someClinetHaveunlimitedPass: "Some of the clients have Unlimited passes.",
    yes: "Yes",
    no: "No",
    deleteCustomer: "Delete Customer",
    delete: "delete"
}