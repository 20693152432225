import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTPListener, HTTPStatus } from './interceptor/HTTPListener';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { RouteReuseService } from './route-reuse/RouteReuseService';
import { MainComponent } from './layout/main/main.component';
import { SharedModule } from './component/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AddEditRoleDialogComponent } from './pages/role-management/addedit-role-dialog/addedit-role-dialog.component';
import { ConfirmationDialogComponent } from './component/confirmation-dialog/confirmation-dialog.component';
import { AddEditUserDialogComponent } from './pages/user-management/add-edit-user-dialog/add-edit-user-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { AddEditCustomerDialogComponent } from './pages/customers/add-edit-customer-dialog/add-edit-customer-dialog.component';
import { AddEditCouponDialogComponent } from './pages/coupon-code/add-edit-coupon-dialog/add-edit-coupon-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ResetPassPageComponent } from './pages/reset-pass/reset-pass-page.component';
import { MatCardModule } from '@angular/material/card';
import { DeleteConfirmationDialogComponent } from './component/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ActiveInactiveDialogComponent } from './component/active-inactive-dialog/active-inactive-dialog.component';
import { MatSelectSearchModule } from 'mat-select-search';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DirtyComponent } from './component/dirty/dirty.component';
import { OrdinalDatePipe } from './services/common/ordinal-date.pipe';
import { ReportStatusComponent } from './component/report-status/report-status.component';
import { UnderMainatanceComponent } from './pages/under-mainatance/under-mainatance.component';
import { AddEventConfirmationDialogComponent } from './pages/order/add-order-dialogs/add-event-confirmation-dialog/add-event-confirmation-dialog.component';
import { AddEventDialogComponent } from './pages/order/add-order-dialogs/add-event-dialog/add-event-dialog.component';
import { AddOrderDialogComponent } from './pages/order/add-order-dialogs/add-order-dialog/add-order-dialog.component';
import { AddSessionDialogComponent } from './pages/order/add-order-dialogs/add-session-dialog/add-session-dialog.component';
import { OrderCartDialogComponent } from './pages/order/add-order-dialogs/order-cart-dialog/order-cart-dialog.component';
import { OrderSuccessDialogComponent } from './pages/order/add-order-dialogs/order-success-dialog/order-success-dialog.component';
import { SessionPassDialogComponent } from './pages/order/add-order-dialogs/session-pass-dialog/session-pass-dialog.component';
import { SessionDetailDialogComponent } from './pages/order/add-order-dialogs/session-detail-dialog/session-detail-dialog.component';
import { ViewMoreSessionDialogComponent } from './pages/order/add-order-dialogs/view-more-session-dialog/view-more-session-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FreezeSessionDialogComponent } from './pages/sessions/freeze-session-dialog/freeze-session-dialog.component';
import { UnFreezeSessionDialogComponent } from './pages/sessions/un-freeze-session-dialog/un-freeze-session-dialog.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTableModule } from '@angular/material/table';
import { FreezeUserTimingsDialogComponent } from './pages/user-management/freeze-user-timings-dialog/freeze-user-timings-dialog.component';


const RxJS_Services = [HTTPListener, HTTPStatus];

@NgModule({
  declarations: [AppComponent, MainComponent, ForgotPasswordComponent, RegistrationComponent, AddEditRoleDialogComponent, ConfirmationDialogComponent, AddEditUserDialogComponent, AddEditCustomerDialogComponent, AddEditCouponDialogComponent, ResetPassPageComponent, DeleteConfirmationDialogComponent, ActiveInactiveDialogComponent, DirtyComponent, ReportStatusComponent, UnderMainatanceComponent, AddEventConfirmationDialogComponent, AddEventDialogComponent, AddOrderDialogComponent, AddSessionDialogComponent, OrderCartDialogComponent, OrderSuccessDialogComponent, SessionPassDialogComponent , SessionDetailDialogComponent , ViewMoreSessionDialogComponent,FreezeSessionDialogComponent,UnFreezeSessionDialogComponent,FreezeUserTimingsDialogComponent],
  imports: [CommonModule, AppRoutingModule, NgbModule, SharedModule, ToastrModule.forRoot({ closeButton: true }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    MatDialogModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatSelectSearchModule,
    NgxMatSelectSearchModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    NgxMaterialTimepickerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [MatDialogModule,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

    {
      provide: RouteReuseStrategy,
      useClass: RouteReuseService,
    },
    RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true,
    },
    DatePipe,
    OrdinalDatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
